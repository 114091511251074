<template>
  <section class="overflow-hidden">
    <BaseChipTitle
      title="Vind werk passend"
      chip="bij jouw interesse"
      center
    />
    <BaseCarousel
      :items="sectors"
      :config="swiperConfig"
      type="sector"
    >
      <template #item="{slug, title, color, active_image, professions}">
        <NuxtLink
          :to="slug"
          class="card-base group relative block overflow-hidden"
        >
          <div
            class="absolute inset-0 z-10 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            :style="{backgroundImage: `linear-gradient(to bottom, transparent, ${color}50)`}"
          />
          <div class="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black/20 opacity-100 transition-opacity duration-500 group-hover:opacity-0" />
          <NuxtPicture
            v-if="active_image"
            :src="active_image.url"
            :img-attrs="{class: 'card-img z-0'}"
            :alt="title"
            sizes="480px xs:960px sm:540px lg:700px"
          />
          <div class="absolute inset-x-4 bottom-6 z-20 space-y-4">
            <div class="text-white lg:text-xl">
              {{ title }}
            </div>
            <FormKit
              v-if="professions?.length"
              type="select"
              placeholder="Kies je functie"
              :options="professions"
              input-class="text-gray-lighter hover:ring-yellow hover:ring-2"
              @click.prevent
              @input="professionSelected"
            />
          </div>
        </NuxtLink>
      </template>
    </BaseCarousel>
  </section>
</template>

<script setup lang="ts">
import sectorQuery from '~/graphql/queries/sector.gql';
import type {Sector} from '~/graphql/graphql';

const swiperConfig = {
  slidesPerView: 1.3,
  spaceBetween: 8,
  breakpoints: {
    786: {slidesPerView: 3.15, spaceBetween: 24},
    1440: {slidesPerView: 4.5, spaceBetween: 24},
  },
};

const router = useRouter();

const professionSelected = (profession) => {
  router.push({name: 'vacatures-als-slug', params: {slug: profession.replace('vacatures-als-', '')}});
};

const {result: sectorResult} = useQuery(sectorQuery);
const sectors = computed<Sector[]>(() => sectorResult.value?.sector ?? []);
</script>
