<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    class="lg:scale-125"
  >
    <path
      d="M9.30426 9.26797C8.86339 9.70883 8.04529 10.5269 7.32257 11.2497C6.54153 12.0307 5.27577 12.0307 4.49472 11.2497C3.78588 10.5408 2.98081 9.73576 2.51302 9.26797C0.637669 7.39262 0.637669 4.35208 2.51302 2.47673C4.38837 0.601384 7.42891 0.601384 9.30426 2.47673C11.1796 4.35208 11.1796 7.39262 9.30426 9.26797Z"
      fill="white"
    />
    <path
      d="M7.70944 5.87235C7.70944 6.86691 6.90319 7.67315 5.90864 7.67315C4.91408 7.67315 4.10784 6.86691 4.10784 5.87235C4.10784 4.8778 4.91408 4.07155 5.90864 4.07155C6.90319 4.07155 7.70944 4.8778 7.70944 5.87235Z"
      fill="white"
    />
    <path
      d="M9.30426 9.26797C8.86339 9.70883 8.04529 10.5269 7.32257 11.2497C6.54153 12.0307 5.27577 12.0307 4.49472 11.2497C3.78588 10.5408 2.98081 9.73576 2.51302 9.26797C0.637669 7.39262 0.637669 4.35208 2.51302 2.47673C4.38837 0.601384 7.42891 0.601384 9.30426 2.47673C11.1796 4.35208 11.1796 7.39262 9.30426 9.26797Z"
      stroke="#333333"
      stroke-width="1.44064"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.70944 5.87235C7.70944 6.86691 6.90319 7.67315 5.90864 7.67315C4.91408 7.67315 4.10784 6.86691 4.10784 5.87235C4.10784 4.8778 4.91408 4.07155 5.90864 4.07155C6.90319 4.07155 7.70944 4.8778 7.70944 5.87235Z"
      stroke="#333333"
      stroke-width="1.44064"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
