<template>
  <BaseCard
    round
    shadow
    class="bg-white px-6 py-12"
  >
    <h2 class="text-md font-semibold leading-6 lg:text-3xl lg:font-semibold">
      Vind werk
    </h2>
    <FormKit
      id="find-work"
      type="form"
      :actions="false"
      @submit="handleSubmitSearch"
    >
      <div
        class="formkit-outer mt-3.5 lg:mt-5"
        data-family="text"
        data-type="text"
        data-suffix-icon="true"
      >
        <div class="formkit-wrapper mb-3.5 lg:mb-5">
          <div class="formkit-inner relative">
            <FormKit
              label="Waar wil je werken?"
              name="city"
              type="text"
              validation="required"
              :delay="200"
              placeholder="Plaats of postcode"
              input-class="mt-2 lg:mt-2.5"
            >
              <template #suffix-icon>
                <button @click="getCurrentLocation">
                  <span class="sr-only">Huidige locatie</span>
                  <IconsLocationPin />
                </button>
              </template>
            </FormKit>
          </div>
        </div>
        <ClientOnly>
          <div
            class="formkit-wrapper"
            :class="{'mb-7': !contactWithRecruiter || sectorChoice !== 'none'}"
          >
            <div class="formkit-inner relative">
              <FormKit
                v-model="sectorChoice"
                label="Beroepsgroep"
                name="sector"
                type="select"
                :options="sectorFilters"
                input-class="mt-2.5"
              />
            </div>
          </div>
        </ClientOnly>
        <div
          v-if="contactWithRecruiter && sectorChoice === 'none'"
          class="relative mb-7 mt-6 rounded-2xl bg-gray-lightest px-4 py-6"
        >
          <IconsClose
            class="absolute right-2.5 top-2.5 w-4 cursor-pointer"
            @click="$formkit.reset('find-work')"
          />
          <p class="text-sm leading-8 lg:text-lg">
            Geen probleem! Neem <NuxtLink
              :to="{name: 'contact'}"
              class="underline underline-offset-1"
            >
              contact
            </NuxtLink> op met een recruiter en we helpen je verder.
          </p>
        </div>
      </div>
      <FormKit
        v-if="sectorChoice !== 'none'"
        type="submit"
        label="Bekijk mijn top 3"
      />
    </FormKit>
    <div
      v-if="sectorChoice !== 'none'"
      class="mt-5 text-xs font-normal leading-5 lg:mt-8 lg:text-base lg:leading-6"
    >
      <NuxtLink
        :to="{name: 'open-sollicitatie'}"
        class="text-violet underline"
      >
        Binnen één minuut solliciteren!
        <span class="block font-bold">Schrijf je dan in</span>
      </NuxtLink>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import {useGeolocation} from '@vueuse/core';
import sectorFilterQuery from '~/graphql/queries/sectorFilter.gql';
import type {SectorFilter} from '~/types';
import type {VacancyFilters} from '~/graphql/graphql';

const contactWithRecruiter = ref(true);
const sectorChoice = ref('');

const router = useRouter();

const {result: sectorFilterResult} = useQuery(sectorFilterQuery);
const sectorFilters = computed<SectorFilter[]>(() => {
  const filters = sectorFilterResult.value?.sector ?? [];

  return [
    {
      label: 'Alle branches',
      value: null,
    },
    ...filters,
  ];
});

const handleSubmitSearch = (formData: VacancyFilters) => {
  router.push({
    name: 'vacatures',
    query: {
      filters: JSON.stringify({
        city: formData.city,
        radius: '20',
        sector: [formData.sector],
      }),
      topThree: 1,
      order: '_geoPoint',
    },
  });
};

const getCurrentLocation = () => {
  const {coords, locatedAt, isSupported} = useGeolocation();
  /*
    TODO:
      - use coords to calculate a location
      - Reverse geocoding to get the location
   */
  console.log(isSupported);
  console.log(coords.value);
  console.log(locatedAt.value);
};

</script>
